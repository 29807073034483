import { lazy } from 'react';
import { createHashRouter } from 'react-router-dom';

const Home = lazy(() => import('../pages/home'));
const Demo = lazy(() => import('../pages/demo'));
const Product = lazy(() => import('../pages/product'));
const Sp = lazy(() => import('../pages/sp'));
const SpError = lazy(() => import('../pages/sp/error'));
const SpSuccess = lazy(() => import('../pages/sp/success'));

const router = createHashRouter([
  {
    path: '/',
    element: <Home />,
    children: [],
  },
  {
    path: '/demo',
    element: <Demo />,
  },
  {
    path: '/product',
    element: <Product />,
  },
  {
    path: '/sp',
    element: <Sp />,
  },
  {
    path: '/sp/error',
    element: <SpError />,
  },
  {
    path: '/sp/success',
    element: <SpSuccess />,
  },
]);

export { router };
